@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400,600|Raleway:200,400);
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#navi-cont {
	width: 15vw;
	height: 100vh;
	grid-row: 1;
	grid-column: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#navi-sub-cont {
	position: fixed;
	text-align: right;
	border-right: .3vw solid #FAA52D;
}

#name {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 2vw;
	color: #FAA52D;
	border-radius: 3px;
	padding: .3vw .5vw;
	-webkit-transition: all .1s;
	transition: all .1s;
	letter-spacing: .1vw;
}

#name:hover {
	-webkit-transition: all .1s;
	transition: all .1s;
	letter-spacing: .2vw;
	cursor: pointer;
}

#selected {
	color: black;
}

.nav-link {
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
	text-decoration: none;
	color: grey;
	font-size: 1.5vw;
	letter-spacing: .1vw;
	-webkit-transition: all .1s;
	transition: all .1s;
}

.nav-link:hover {
	-webkit-transition: all .1s;
	transition: all .1s;
	letter-spacing: .2vw;
	font-size: 1.7vw;
	cursor: pointer;
}

.nav-element {
	margin-bottom: 1.5vw;
	padding-right: .5vw;
	height: 2vw;
}
#project-list-cont {
	margin-top: 5vw;
	grid-row: 1;
	grid-column: 2;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#project-cont {
	grid-column: 2;
	margin: 5vw;
	display: flex;
	flex-direction: column;
}

#purpose, #timeline, #back {
	font-size: 1.4vw;
}

#info-cont {
	margin-top: 1vw;
}

#info-sub {
	margin-top: 4vw;
	margin-bottom: 4vw;
	font-size: 2vw;
	text-align: center;
	font-family: Open Sans, sans-serif;
	font-weight: 600;
	color: #FAA52D;
}

.project-sub-img {
	width: 100%;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.project-sub-audio {
	width: 100%;
}

.content-block {
	display: grid;
	grid-template-columns: 1fr 4fr;
	margin-top: 1vw;
}

.content-block-section {
	font-size: 1.5vw;
	font-family: Open Sans, sans-serif;
	font-weight: 600;
	text-align: right;
	margin-right: 1vw;
}

.content-block-content {
	font-size: 1.2vw;
}

.project-card-cont {
	width: 32vw;
	height: 21vw;
	padding: 1vw;
	text-align: right;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.project-img-cont {
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: grid;
	width: 32vw;
	height: 18vw;
	grid-template-rows: 32vw;
	grid-template-columns: 18vw;
	background-color: white;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	margin-bottom: .3vw;
}

.project-img:hover {
	-webkit-transition: all .3s;
	transition: all .3s;
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	opacity: 0.5;
	cursor: pointer;
}

.project-img {
	grid-row: 1;
	grid-column: 1;
	width: 32vw;
	height: 18vw;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.project-img-cover {
	grid-row: 1;
	grid-column: 1;
	width: 32vw;
	height: 18vw;
	background-color: white;
}

.project-name {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.project-tech {
	font-weight: 300;
	font-family: "Open Sans", sans-serif;
	font-size: 1vw;
}
#dash-projects {
	display: flex;
	flex-wrap: wrap;
	width: 70vw;
	margin-top: 1vw;
}

#back {
	cursor: pointer
}
#about-cont {
	grid-column: 2;
	margin: 5vw;
	display: grid;
	grid-template-rows: 10vh 60vh;
	grid-template-columns: 40vw 20vw;
}

#about-content {
	font-size: 1.5vw;
	margin-top: 2vw;
	font-weight: 300;
	font-family: "Open Sans", sans-serif;
}

#about-me {
	grid-column: 2;
	grid-row: 2;
	margin-top: 15vh;
	width: 100%;
	-webkit-transform: rotate(6deg) translate(0, -6vw);
	        transform: rotate(6deg) translate(0, -6vw);
	border: .8vw solid white;
	box-shadow: -.5vw .5vw 1vw 0 #888888;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#about-contact-links-cont {
	grid-column: 1;
	display: flex;
	width: 20vw;
	margin-top: 3vw;
	margin-left: 1vw;
	justify-content: space-around;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
#main-cont {
	display: grid;
	grid-template-columns: 18vw 70vw;
	overflow: hidden;
	font-family: "Open Sans", sans-serif;
	padding-left: 100vw;
}

#app-cont {
	grid-row: 1;
	grid-column: 2;
}

#background-image {
	position: fixed;
	z-index: -1;
	height: 150%;
	top: -35%;
	left: -10%;
	opacity: 0.2;
	-webkit-transition: all .5s;
	transition: all .5s;
}

#gallery-cont {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.title {
	font-size: 3vw;
	font-family: Montserrat, sans-serif;
	font-weight: bolder;
	color: #FAA52D;
	border-bottom: .2vw solid #FAA52D;
}

#cover-pane {
	width: 100vw;
	height: 100vh;
	/*position: absolute;*/
	background-color: black;
	z-index: 10;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-left: -100vw;
	grid-column: 1/3;
	grid-row: 1;
}

#redesign-frame {
	border: none;
	width: 100%;
	height: 100%;
}

#redesign-frame-cont {
	overflow-x: auto;
	overflow-y: hidden;
	resize: both;
	width: 70vw;
	height: 45vw;
	display: flex;
}

#redesign-bottom-row {
	height: 100vh;
	grid-column: 1/3;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

#cover-hey-there {
	margin-left: 5vw;
	margin-top: 5vw;
	color: white;
	font-weight: bold;
	font-size: 5vw;
	font-family: Montserrat, sans-serif;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#loading {
	color: white;
	font-size: 3vw;
	text-align: center;
	margin-top: 4vw;
	align-self: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.gallery-img {
	width: 100%;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	margin: 0;
}

.gallery-link {
	width: 30%;
	margin-top: 2vw;
}

.iframe {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.table {
	width: 100%
}

.table-heading {
	text-align: left;
	width: 33%
}

.table td {
	font-size: 1vw;
	padding: 0 1vw;
}

.table ul {
	padding-left: 0;
}
.avenir {
	font-family: Montserrat, sans-serif;
}

.home-links {
	font-size: 1.8vw;
	background-color: black;
	color: white;
	width: 10vw;
	height: 2.5vw;
	text-align: center;
	padding: .5vw 1vw;
	-webkit-transition: all .2s;
	transition: all .2s;
	letter-spacing: .1vw;
}

.home-links:hover {
	-webkit-transition: all .2s;
	transition: all .2s;
	font-size: 1.9vw;
	cursor: pointer;
	background-color: #FAA52D;
	letter-spacing: .2vw;
}

.home-contact-link {
	width: 3vw;
	height: 3vw;
	background-color: white;
	border-radius: .5vw;
	padding: .5vw;
	box-shadow: .25vw .25vw .5vw 0 #888888;
	-webkit-transition: all .3s;
	transition: all .3s;
}

.home-contact-link:hover {
	-webkit-transition: all .3s;
	transition: all .3s;
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	background-color: #FAA52D;
	cursor: pointer;
}

#home {
	grid-column: 1/3;
	grid-row: 1/3;
	display: grid;
	grid-template-rows: 5vw 8vw 10vw 4vw 8vw 6vw 5vw 3vw;
	grid-template-columns: 5vw 65vw 25vw 5vw;
	margin-left: -100vw;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#home-contact-links-cont {
	grid-row: 7;
	grid-column: 2;
	display: flex;
	width: 20vw;
	margin-left: 1vw;
	justify-content: space-around;
}

#hey-there {
	font-weight: bold;
	font-size: 5vw;
	grid-column: 2/4;
	grid-row: 2;
}

#home-desc {
	grid-row: 3;
	grid-column: 2/4;
	font-size: 1.8vw;
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
}

#me {
	grid-row: 4/7;
	grid-column: 3;
	width: 100%;
	-webkit-transform: rotate(3deg) translate(-5vw, -10vw);
	        transform: rotate(3deg) translate(-5vw, -10vw);
	border: .8vw solid white;
	box-shadow: .5vw -.5vw 1vw 0 #888888;
}

#home-learn {
	grid-row: 4;
	grid-column: 2;
	font-size: 2.5vw;
	font-weight: bolder;
}

#home-contact {
	margin-top: 2vw;
	grid-row: 6;
	grid-column: 2;
	font-size: 2.5vw;
	font-weight: bolder;
}

#home-links-cont {
	display: flex;
	grid-row: 5;
	grid-column: 2;
	justify-content: space-around;
	width: 50vw;
}
