@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:300,400,600|Raleway:200,400');

#main-cont {
	display: grid;
	grid-template-columns: 18vw 70vw;
	overflow: hidden;
	font-family: "Open Sans", sans-serif;
	padding-left: 100vw;
}

#app-cont {
	grid-row: 1;
	grid-column: 2;
}

#background-image {
	position: fixed;
	z-index: -1;
	height: 150%;
	top: -35%;
	left: -10%;
	opacity: 0.2;
	transition: all .5s;
}

#gallery-cont {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.title {
	font-size: 3vw;
	font-family: Montserrat, sans-serif;
	font-weight: bolder;
	color: #FAA52D;
	border-bottom: .2vw solid #FAA52D;
}

#cover-pane {
	width: 100vw;
	height: 100vh;
	/*position: absolute;*/
	background-color: black;
	z-index: 10;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-left: -100vw;
	grid-column: 1/3;
	grid-row: 1;
}

#redesign-frame {
	border: none;
	width: 100%;
	height: 100%;
}

#redesign-frame-cont {
	overflow-x: auto;
	overflow-y: hidden;
	resize: both;
	width: 70vw;
	height: 45vw;
	display: flex;
}

#redesign-bottom-row {
	height: 100vh;
	grid-column: 1/3;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

#cover-hey-there {
	margin-left: 5vw;
	margin-top: 5vw;
	color: white;
	font-weight: bold;
	font-size: 5vw;
	font-family: Montserrat, sans-serif;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#loading {
	color: white;
	font-size: 3vw;
	text-align: center;
	margin-top: 4vw;
	align-self: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.gallery-img {
	width: 100%;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	margin: 0;
}

.gallery-link {
	width: 30%;
	margin-top: 2vw;
}

.iframe {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
}

.table {
	width: 100%
}

.table-heading {
	text-align: left;
	width: 33%
}

.table td {
	font-size: 1vw;
	padding: 0 1vw;
}

.table ul {
	padding-left: 0;
}