#project-list-cont {
	margin-top: 5vw;
	grid-row: 1;
	grid-column: 2;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#project-cont {
	grid-column: 2;
	margin: 5vw;
	display: flex;
	flex-direction: column;
}

#purpose, #timeline, #back {
	font-size: 1.4vw;
}

#info-cont {
	margin-top: 1vw;
}

#info-sub {
	margin-top: 4vw;
	margin-bottom: 4vw;
	font-size: 2vw;
	text-align: center;
	font-family: Open Sans, sans-serif;
	font-weight: 600;
	color: #FAA52D;
}

.project-sub-img {
	width: 100%;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.project-sub-audio {
	width: 100%;
}

.content-block {
	display: grid;
	grid-template-columns: 1fr 4fr;
	margin-top: 1vw;
}

.content-block-section {
	font-size: 1.5vw;
	font-family: Open Sans, sans-serif;
	font-weight: 600;
	text-align: right;
	margin-right: 1vw;
}

.content-block-content {
	font-size: 1.2vw;
}

.project-card-cont {
	width: 32vw;
	height: 21vw;
	padding: 1vw;
	text-align: right;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.project-img-cont {
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: grid;
	width: 32vw;
	height: 18vw;
	grid-template-rows: 32vw;
	grid-template-columns: 18vw;
	background-color: white;
	box-shadow: .2vw .2vw .3vw 0 #888888;
	margin-bottom: .3vw;
}

.project-img:hover {
	transition: all .3s;
	transform: scale(1.1);
	opacity: 0.5;
	cursor: pointer;
}

.project-img {
	grid-row: 1;
	grid-column: 1;
	width: 32vw;
	height: 18vw;
    transition: all 0.2s;
}

.project-img-cover {
	grid-row: 1;
	grid-column: 1;
	width: 32vw;
	height: 18vw;
	background-color: white;
}

.project-name {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
}

.project-tech {
	font-weight: 300;
	font-family: "Open Sans", sans-serif;
	font-size: 1vw;
}