#about-cont {
	grid-column: 2;
	margin: 5vw;
	display: grid;
	grid-template-rows: 10vh 60vh;
	grid-template-columns: 40vw 20vw;
}

#about-content {
	font-size: 1.5vw;
	margin-top: 2vw;
	font-weight: 300;
	font-family: "Open Sans", sans-serif;
}

#about-me {
	grid-column: 2;
	grid-row: 2;
	margin-top: 15vh;
	width: 100%;
	transform: rotate(6deg) translate(0, -6vw);
	border: .8vw solid white;
	box-shadow: -.5vw .5vw 1vw 0 #888888;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#about-contact-links-cont {
	grid-column: 1;
	display: flex;
	width: 20vw;
	margin-top: 3vw;
	margin-left: 1vw;
	justify-content: space-around;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}