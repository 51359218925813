#navi-cont {
	width: 15vw;
	height: 100vh;
	grid-row: 1;
	grid-column: 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#navi-sub-cont {
	position: fixed;
	text-align: right;
	border-right: .3vw solid #FAA52D;
}

#name {
	font-family: "Open Sans", sans-serif;
	font-weight: 600;
	font-size: 2vw;
	color: #FAA52D;
	border-radius: 3px;
	padding: .3vw .5vw;
	transition: all .1s;
	letter-spacing: .1vw;
}

#name:hover {
	transition: all .1s;
	letter-spacing: .2vw;
	cursor: pointer;
}

#selected {
	color: black;
}

.nav-link {
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
	text-decoration: none;
	color: grey;
	font-size: 1.5vw;
	letter-spacing: .1vw;
	transition: all .1s;
}

.nav-link:hover {
	transition: all .1s;
	letter-spacing: .2vw;
	font-size: 1.7vw;
	cursor: pointer;
}

.nav-element {
	margin-bottom: 1.5vw;
	padding-right: .5vw;
	height: 2vw;
}