.avenir {
	font-family: Montserrat, sans-serif;
}

.home-links {
	font-size: 1.8vw;
	background-color: black;
	color: white;
	width: 10vw;
	height: 2.5vw;
	text-align: center;
	padding: .5vw 1vw;
	transition: all .2s;
	letter-spacing: .1vw;
}

.home-links:hover {
	transition: all .2s;
	font-size: 1.9vw;
	cursor: pointer;
	background-color: #FAA52D;
	letter-spacing: .2vw;
}

.home-contact-link {
	width: 3vw;
	height: 3vw;
	background-color: white;
	border-radius: .5vw;
	padding: .5vw;
	box-shadow: .25vw .25vw .5vw 0 #888888;
	transition: all .3s;
}

.home-contact-link:hover {
	transition: all .3s;
	transform: scale(1.1);
	background-color: #FAA52D;
	cursor: pointer;
}

#home {
	grid-column: 1/3;
	grid-row: 1/3;
	display: grid;
	grid-template-rows: 5vw 8vw 10vw 4vw 8vw 6vw 5vw 3vw;
	grid-template-columns: 5vw 65vw 25vw 5vw;
	margin-left: -100vw;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#home-contact-links-cont {
	grid-row: 7;
	grid-column: 2;
	display: flex;
	width: 20vw;
	margin-left: 1vw;
	justify-content: space-around;
}

#hey-there {
	font-weight: bold;
	font-size: 5vw;
	grid-column: 2/4;
	grid-row: 2;
}

#home-desc {
	grid-row: 3;
	grid-column: 2/4;
	font-size: 1.8vw;
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
}

#me {
	grid-row: 4/7;
	grid-column: 3;
	width: 100%;
	transform: rotate(3deg) translate(-5vw, -10vw);
	border: .8vw solid white;
	box-shadow: .5vw -.5vw 1vw 0 #888888;
}

#home-learn {
	grid-row: 4;
	grid-column: 2;
	font-size: 2.5vw;
	font-weight: bolder;
}

#home-contact {
	margin-top: 2vw;
	grid-row: 6;
	grid-column: 2;
	font-size: 2.5vw;
	font-weight: bolder;
}

#home-links-cont {
	display: flex;
	grid-row: 5;
	grid-column: 2;
	justify-content: space-around;
	width: 50vw;
}